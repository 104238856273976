import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
  fetchAuthenticateWithTokenRequest,
  fetchUserDetailsRequest,
  postRequestPasswordResetRequest,
} from './login-redux/actions';
import { ROUTE_PATH } from '../../routes/paths';
import { CustomInput, Spinner } from '../common';
import { LOCLogo } from '../../assets/images';
import { Checkbox } from '@kindlyhuman/component-library';
import useAuth from '../../hooks/useAuth';
import { SwitchboardFeatures, useFeatureFlag } from '../../hooks/useFeatureFlags';

type props = {
  login: any;
  pending: boolean;
  userDetails: any;
  authenticateWithToken: any;
  authenticateWithTokenError: any;
};

declare global {
  interface Window {
    branch: any;
  }
}
const LoginDetails: React.FunctionComponent<props> = ({
  login,
  pending,
  userDetails,
  authenticateWithToken,
  authenticateWithTokenError,
}): JSX.Element => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const user_id = params.get('user_id');

  if (token && user_id) {
    localStorage.setItem('reset_token', token);
    localStorage.setItem('user_id', user_id);
  }

  const keycloakFeatureFlag = useFeatureFlag(SwitchboardFeatures.SB_KEYCLOAK_LOGIN);

  const [userName, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [trusted, setTrusted] = useState<boolean>(false);
  const [formError, setFormError] = useState<any>();

  const history = useHistory();
  const { loginClassic, keycloak, authToken } = useAuth();

  const dispatch = useDispatch();
  const isFirstRun = useRef<boolean>(false);

  const validate = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (userName === '' || userName === undefined || !emailRegex.test(userName)) {
      return setFormError({ userName: 'Enter valid email address' });
    } else if (password === '' || password === undefined || password.length < 8) {
      if (password !== undefined && password === '') {
        return setFormError({ password: 'Enter password' });
      } else if (password !== undefined && password.length < 8) {
        return setFormError({ password: 'Enter min. 8 characters' });
      }
    } else {
      setFormError({});
    }

    loginClassic(userName, password, trusted);
  };

  const handleForgotPassword = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (userName === '' || userName === undefined || !emailRegex.test(userName)) {
      return setFormError({ userName: 'Enter valid email address' });
    }
    setFormError({});
    dispatch(
      postRequestPasswordResetRequest({
        email_address: userName,
        from_location: 'switchboard',
      }),
    );
  };

  useEffect(() => {
    if (keycloakFeatureFlag.isLoading) {
      return;
    }

    if (!authToken && keycloak && keycloakFeatureFlag.enabled) {
      keycloak.login();
    }
  }, [keycloak, authToken, keycloakFeatureFlag.isLoading]);

  useEffect(() => {
    // we want to avoid sending multiple concurrent `POST authenticate_with_token` requests,
    // as this can unintentionally invalidate the token due to overusage
    if (isFirstRun.current) return;

    isFirstRun.current = true;

    if (token && user_id) {
      dispatch(
        fetchAuthenticateWithTokenRequest({
          token: token,
          userId: user_id,
        }),
      );
    }
  }, [keycloak, authToken, keycloakFeatureFlag.isLoading]);

  useEffect(() => {
    if (authenticateWithToken?.data) {
      const userData = authenticateWithToken?.data;
      if (
        userData.client_administrator_role.status === 'enabled' &&
        userData.client_administrator_role.type === 'client_administrator'
      ) {
        history.push(ROUTE_PATH.SNAPSHOT);
      } else if (
        userData.client_administrator_role.status === 'invited' &&
        userData.client_administrator_role.type === 'client_administrator'
      ) {
        dispatch(fetchUserDetailsRequest({}));
        history.push(ROUTE_PATH.ONBOARDING);
      }
    }
  }, [authenticateWithToken]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authenticateWithTokenError?.status === 404) {
      history.push(ROUTE_PATH.REQUESTNEWINVITE);
    } else if (authenticateWithTokenError?.status === 400) {
      history.push(ROUTE_PATH.INVITATION_COMPLETED);
    }
  }, [authenticateWithTokenError]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      onKeyDown={(e: any) => {
        if (e.key === 'Enter') {
          validate();
        }
      }}
      tabIndex={0}
    >
      <div className="flex h-screen bg-gray-100">
        {pending ? (
          <Spinner />
        ) : (
          <div className="w-98 m-auto bg-white p-10">
            <div className="flex justify-center items-center">
              <img className="w-4/5" src={LOCLogo} alt="loc-logo" />
            </div>
            <div className="grid grid-cols-1 gap-5 mt-8">
              <CustomInput
                inputType={'text'}
                value={userName}
                setValue={setUsername}
                Label={'Username'}
                Error={formError?.userName}
                ErrorMessage={formError?.userName}
                labelClass="font-normal"
              />
              <CustomInput
                inputType={'password'}
                value={password}
                setValue={setPassword}
                Label={'Password'}
                Error={formError?.password}
                ErrorMessage={formError?.password}
                labelClass="font-normal"
              />
              <div className="flex gap-x-4 pt-4 pl-2">
                <Checkbox
                  variant="DEFAULT"
                  defaultChecked={trusted}
                  checked={trusted}
                  onChange={() => setTrusted((prev) => !prev)}
                />
                <div className="text-gray-900 text-base">Trust this device</div>
              </div>
              <button
                type="button"
                className="bg-bright-blue w-full h-11.5 rounded-full text-white font-bold tracking-wide"
                onClick={validate}
              >
                Submit
              </button>
              <div
                className="w-full text-center blue-primary underline font-bold"
                role="button"
                onClick={handleForgotPassword}
              >
                Forgot Password
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    login: state.login?.loginDetails?.data || null,
    pending: state.login.pending,
    userDetails: state.login.UserDetails,
    authenticateWithToken: state.login?.authenticateWithToken,
    authenticateWithTokenError: state.login.authenticateWithTokenError,
  };
};

export default connect(mapStateToProps)(LoginDetails);
